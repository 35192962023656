import { useCallback } from "react";

import useClient from "hooks/useClient";

const useGetUser = (): {
  getUser: () => Promise<User>;
} => {
  const { client } = useClient();

  const getUser = useCallback(async () => {
    const { data } = await client({
      method: "get",
      url: "/user",
    });

    const user = data as User;
    user.isRequiredMfa = true;
    return user;
  }, [client]);

  return { getUser };
};

export default useGetUser;
